<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>고객 사례</h2>
                    <!-- <p>GiGA 네트워크는 물론 랙호스팅, 운용대행까지 ICT Total Care 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a>PRODUCTS</a></li>
                        <li><a>GiGAoffice</a></li>
                        <li><span>고객사례</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="sub-section ctcas-giga bgGray">
            <div class="innWrap">
                <div class="ctcas-movWrap">
                    <div class="mov-thumb">
                        <a class="pop-open" @click="openPopup(0)">
                            <span class="btn-play"></span>
                            <img src="@/assets/images/gigaCTCas_01.png" alt="동영상이미지" >
                        </a>
                    </div>
                    <div class="mov-txt right">
                        <p class="tit ">KT가 귀사의 장비와  서비스를<br />운영합니다.</p>
                        <p>KT GiGA office 센터에서는 전문가의 24시간 관리,<br />1:1 상담 및 긴급 출동, 보안 및 통합 관제까지 제공합니다.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="sub-section ctcas-giga">
            <div class="innWrap">
                <div class="ctcas-movWrap">
                    <div class="mov-txt left">
                        <p class="tit ">귀사의 업무 속도는 빨라지고,<br />서버 보안도 훨씬 강력해집니다.</p>
                        <p>
                            이제 최첨단 전산실을 빌려쓸 수 있는 시대!<br />서버구축비, 운영비를 절감하는 것은 물론,<br />서버 소음이 사라지고, 공간도 넓고 쾌적하게 바뀝니다.
                        </p>
                    </div>
                    <div class="mov-thumb">
                        <a class="pop-open" @click="openPopup(1)">
                            <span class="btn-play"></span>
                            <img src="@/assets/images/gigaCTCas_02.png" alt="동영상이미지" >
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="sub-section ctcas-giga bgGray">
            <div class="innWrap">
                <div class="ctcas-movWrap">
                    <div class="mov-thumb">
                        <a class="pop-open" @click="openPopup(2)">
                            <span class="btn-play"></span>
                            <img src="@/assets/images/gigaCTCas_03.png" alt="동영상이미지" >
                        </a>
                    </div>
                    <div class="mov-txt right">
                        <p class="tit ">KT GiGA office<br />회사의 전산장비 고민 해결</p>
                        <p>
                            전산장비로 인한 회사의 고민들!<br />이제 KT의 GiGA office에게 맡기세요.<br />회사의 전산장비를 KT의 최첨단 시설로 옮기면<br />비용은 줄어들고 속도는 빨라지고, 공간까지 넓어지니까<br />앞서가는 오피스의 IT경쟁력! KT GiGA office
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="sub-section ctcas-giga">
            <div class="innWrap">
                <div class="ctcas-movWrap">
                    <div class="mov-txt left">
                        <p class="tit ">KT biz GiGA office, Managed 보안 상품<br />사무실 환경을 위한 안정적인 네트워크</p>
                        <p>
                            안정적이고 빠른 업무 환경을 위해 GiGA office의 안정적인 ICT<br />솔루션을 이용해보세요. 사무실 근처 KT지사에 GiGA LAN을<br />연결하여 안정적인 네트워크는 물론 장비 호스팅, 보안, 통합 관제<br />등 회사에 필요한 것들을 한번에 이용할 수 있습니다.
                        </p>
                    </div>
                    <div class="mov-thumb">
                        <a class="pop-open" @click="openPopup(3)">
                            <span class="btn-play"></span>
                            <img src="@/assets/images/gigaCTCas_04.png" alt="동영상이미지" >
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </main>
		<!-- pop[S] -->
		<div id="pop-mov01" class="pop-wrap" v-show="popupFlag" style="display: block;">
				<div class="pop-inner pop-lg">
						<div class="pop-tit">
								{{curTitle}}
								<a @click="popupFlag=false" class="close pop-close-btn"></a>
						</div>
						<div class="pop-con">
								<iframe width="100%" height="590px" :src="curUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
				</div>
		</div>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
			popupFlag: false,
			mov: [
				{ title: 'KT가 귀사의 장비와 서비스를 운영합니다.', url: 'https://www.youtube.com/embed/rWJ-o9O3ZFs#ytp-id-31' },
				{ title: '귀사의 업무 속도는 빨라지고,서버 보안도 훨씬 강력해집니다.', url: 'https://www.youtube.com/embed/hxs5ZuvDA8c' },
				{ title: 'KT GiGA office', url: 'https://www.youtube.com/embed/ZGHQD-FDUac' },
				{ title: 'KT biz GiGA office, Managed 보안 상품', url: 'https://www.youtube.com/embed/lGhHLmk3jqI' }
			],
			curTitle: '',
			curUrl: ''
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
    openPopup (num) {
			this.curTitle = this.mov[num].title
			this.curUrl = this.mov[num].url
			this.popupFlag = true
    }
  }
}
</script>